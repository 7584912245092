import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  usuario: string = "";
  nombre: string = "";
  email: string = "";
  password: string = "";
  repassword: string = "";
  cod_cliente: string = "";


  constructor(
    private ruta: ActivatedRoute,
    private router: Router,
    private _login: LoginService
  ) {
    this.cod_cliente = this.ruta.snapshot.params['codigo'];
   }

  ngOnInit() {
  }

  registrar(){
    if(this.password !== this.repassword){
      swal.fire('Oops...', 'El password no coincide', 'error');
    }
    
    let data = {
      cod_cliente: this.cod_cliente,
      usuario: this.usuario,
      nombre: this.nombre,
      email: this.email,
      password: this.password
    };

    this._login.registro(data)
        .subscribe((resp: any) => {
          if(resp.data !== null){
            this.router.navigateByUrl('login');
          } else {
            swal.fire('Oops...', resp.mensaje, 'error');
          }
        });
  }

}
