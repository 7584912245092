import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-redes-sociales',
  templateUrl: './redes-sociales.component.html',
  styleUrls: ['./redes-sociales.component.scss']
})
export class RedesSocialesComponent implements OnInit {

  color: string = '#3F51B5';
  public cod_cliente: string;
  public url: string;

  // Inputs Generales
  @Input() Imagen: string = 'assets/img/sin_imagen.jpg';
  @Input() Titulo: string = 'Ingrese Titulo';
  @Input() Texto: string = 'Ingrese un texto';

  // Inputs por Red Social
  @Input() UrlWeb: string;
  @Input() TextoWeb: string = 'Visitanos OnLine';
  @Input() UrlFacebook: string;
  @Input() TextoFacebook: string = 'Hacerse Fan';
  @Input() UrlTwitter: string;
  @Input() TextoTwitter: string = 'Siguenos';
  @Input() UrlInstagram: string;
  @Input() TextoInstagram: string = 'Siguenos';
  @Input() UrlYoutube: string;
  @Input() TextoYoutube: string = 'Mira nuestros videos';
  @Input() UrlTrip: string;
  @Input() TextoTrip: string = 'Escríbanos una opinión';
  @Input() UrlGoogle: string;
  @Input() TextoGoogle: string = 'Escríbanos una opinión';
  @Input() UrlLinkedin: string;
  @Input() TextoLinkedin: string = 'Unete a mi red';
  // Propiedades
  @Input() usaWeb: boolean = false;
  @Input() usaFacebook: boolean = false;
  @Input() usaTwitter: boolean = false;
  @Input() usaInstagram: boolean = false;
  @Input() usaYoutube: boolean = false;
  @Input() usaTrip: boolean = false;
  @Input() usaGoogle: boolean = false;
  @Input() usaLinkedin: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

}
