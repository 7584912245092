import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sitio-web',
  templateUrl: './sitio-web.component.html',
  styleUrls: ['./sitio-web.component.scss']
})
export class SitioWebComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
