import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'botonera-form',
  templateUrl: './botonera-form.component.html',
  styleUrls: ['./botonera-form.component.scss']
})
export class BotoneraFormComponent implements OnInit {

  @Output() Grabar: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private _location: Location
  ) { }

  ngOnInit() {
  }

  volverAtras() {
    this._location.back();
  }

  saveRecord(){
    this.Grabar.emit();
  }

}
