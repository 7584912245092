import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'botonera-tabla',
  templateUrl: './botonera-tabla.component.html',
  styleUrls: ['./botonera-tabla.component.scss']
})
export class BotoneraTablaComponent implements OnInit {

  @Input() Id: string;
  @Input() Generar: boolean;
  @Output() Remove: EventEmitter<any> = new EventEmitter<any>();
  @Input() habilitado: string;
  @Output() Habilitar: EventEmitter<any> = new EventEmitter<any>();
  @Output() Deshabilitar: EventEmitter<any> = new EventEmitter<any>();
  @Output() GenerarCodigos: EventEmitter<any> = new EventEmitter<any>();
  @Output() EditarCodigos: EventEmitter<any> = new EventEmitter<any>();
  _habilitado: boolean;

  constructor(
  ) { }

  ngOnInit() {
    if(this.habilitado === '1'){
      this._habilitado = true;
    } else {
      this._habilitado = false;
    }
  }

  habilitar(){
    this.Habilitar.emit();
  }

  deshabilitar(){
    this.Deshabilitar.emit();
  }

  eliminar(){
    this.Remove.emit();
  }

  generar() {
    this.GenerarCodigos.emit();
  }

  editar() {
    this.EditarCodigos.emit();
  }

}
