import { Injectable, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { 
  ClientesService,
  CodigosService,
  //PoisService,
  //PromocionesService,
  UsuariosService
} from "./index.services";
import { URL_SERVICIOS, HEADER } from '../config/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuxiliaresService implements OnInit{

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Input() servicio: string;
  _servicio: any;
  cod_cliente: string;
  id: string;
  
  public imagenesChanged : Subject<any> = new Subject<any>()

  constructor(
    private http: HttpClient,
    private _clientes: ClientesService,
    //private _pois: PoisService,
    //private _promociones: PromocionesService,
    private _codigos: CodigosService,
    private _usuarios: UsuariosService
  ) { }

  ngOnInit(){
    
  }

  emitImagenesChangesEvent(input: any, tipo: string) {
    let cambios = { input, tipo }
    this.imagenesChanged.next(cambios);
  }

  verifica_servicio(servicio: string){
    switch (servicio.toUpperCase()) {
      case 'CLIENTES':
          return this._servicio = this._clientes;
          break;
      //case 'POIS':
      //    return this._servicio = this._pois;
      //    break;
      //case 'PROMOCIONES':
      //    return this._servicio = this._promociones;
      //    break;
      case 'CODIGOS':
          return this._servicio = this._codigos;
          break;
      case 'USUARIOS':
          return this._servicio = this._usuarios;
          break;    
    
      default:
        break;
    }
  }

  refresh_imagen(): Observable<any> {
    return this._servicio.obtener(this.cod_cliente, this.id);
  }

  obtener_paises(){
    let url = URL_SERVICIOS + '/Auxiliares/paises/' + this.cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_tipos(){
    let url = URL_SERVICIOS + '/Auxiliares/tipos/' + this.cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_campanias(){
    let url = URL_SERVICIOS + '/Auxiliares/campanias/' + this.cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_plantillas(){
    let url = URL_SERVICIOS + '/Auxiliares/plantillas/' + this.cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_niveles(){
    let url = URL_SERVICIOS + '/Auxiliares/niveles/' + this.cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_codigos(campania: string){
    let url = URL_SERVICIOS + '/Auxiliares/codigos/' + this.cod_cliente + '/' + campania;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_url_plantilla(id: string){
    let url = URL_SERVICIOS + '/Auxiliares/urlplantilla/' + this.cod_cliente + '/' + id;
    return this.http.get(url, { headers: HEADER }); 
  }

}
