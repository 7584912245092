export class Usuario {

    constructor(
        public id?: string,
        public email?: string,
        public password?: string,
        public username?: string,
        public name?: string,
        public last_login?: string,
        public created_at?: string,
        public updated_at?: string,
        public ncliente?: string,
        public nivel?: string,
        public status?: string,
        public img?: string,
        public google?: boolean,
        /*public nombre: string,
        public email: string,
        public password: string,
        public img?: string,
        public role?: string,
        public google?: boolean,
        public _id?: string,
        public id?: string,
        public usuario?: string,
        public nivel?: string, 
        public apellido?: string, 
        public dni?: string, 
        public telefono?: string, 
        public cliente?: string, 
        public ncliente?: number*/
    ) { }

}
