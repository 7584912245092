import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS, HEADER } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class CodigosService {

  constructor(
    private http: HttpClient
  ) { }

  validar_codigo(cod_cliente: string, codigo: string){
    let url = URL_SERVICIOS + '/Codigos/' + cod_cliente + '/' + codigo;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_codigos(cod_cliente: string){
    let url = URL_SERVICIOS + '/Codigos/todos/' + cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_codigo(cod_cliente: string, id: string){
    let url = URL_SERVICIOS + '/Codigos/'+cod_cliente+'/'+id;
    return this.http.get(url, { headers: HEADER } ); 
  }

  nuevo_codigo(cod_cliente: string, cliente: any){
    let url = URL_SERVICIOS + '/Codigos/'+cod_cliente;
    return this.http.put(url, cliente, { headers: HEADER }); 
  }

  editar_codigo(cod_cliente: string, id: string, cliente: any){
    let tipo = 'EDITAR';
    let url = URL_SERVICIOS + '/Codigos/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, cliente, { headers: HEADER }); 
  }

  habilitar_codigo(cod_cliente: string, id: string){
    let tipo = 'HABILITAR';
    let url = URL_SERVICIOS + '/Codigos/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '1' }, { headers: HEADER }); 
  }

  deshabilitar_codigo(cod_cliente: string, id: string){
    let tipo = 'DESHABILITAR';
    let url = URL_SERVICIOS + '/Codigos/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '0' }, { headers: HEADER }); 
  }

  eliminar_codigo(cod_cliente: string, id: string){
    let tipo = 'ELIMINAR';
    let url = URL_SERVICIOS + '/Codigos/'+cod_cliente+'/'+id;
    return this.http.delete(url, { headers: HEADER }); 
  }
}
