import { Component, OnInit, Input, HostBinding, HostListener } from '@angular/core';
import { imgCargando, sinImagen } from '../../config/auxiliar';
import { URL_SERVICIOS } from '../../config/config';
import { AuxiliaresService } from '../../services/auxiliares.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'imagenes',
  templateUrl: './imagenes.component.html',
  styleUrls: ['./imagenes.component.scss']
})
export class ImagenesComponent implements OnInit {

  @Input() Imagen: any[] = [];
  //@Input() Imagen: Observable<any>;
  _imagen: any[] = [ imgCargando ];
  slides: { image: string }[] = [];
  subscription: Subscription;
  
  
  constructor(
    private _aux: AuxiliaresService,
    private router: Router
  ) { }

  
  ngOnInit() {
    setTimeout(() => {
      this.cargar();
      this.escuchar();
    },1500);  
  }

  cargar(){
    console.log('Imagen',this.Imagen);
    if(this.Imagen === null){
      this._imagen = [];
      this._imagen.push(sinImagen);
    } else {
      if( this.Imagen.length === 0 ){
        this._imagen = [];
        this._imagen.push(sinImagen);
      } else {
        this._imagen = [];
        this._imagen = this.Imagen;
        //for (let i = 0; i < this.Imagen.length; i++) {
        //  this._imagen.push( URL_SERVICIOS+'/'+this.Imagen[i].img);
        //}
      }
    } 
    
  }

  escuchar(){
    this._aux.imagenesChanged.subscribe((cambios)=> {
      let data;
      console.log('Cambio en Imagenes',cambios);
      if(cambios.tipo.toUpperCase() === "POIS"){
        data = cambios.input.features[0].properties.img;
      }
      if(cambios.tipo.toUpperCase() === "PROMOCIONES"){
        data = cambios.input.img;
      }
      
      this._imagen = [];
      for (let i = 0; i < data.length; i++) {
        this._imagen.push( URL_SERVICIOS+'/'+data[i].img);
      }
    });
  }

}
