import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  //selector: 'app-root',
  //templateUrl: './app.component.html',
  //styleUrls: ['./app.component.scss']
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  title = 'AppGestion';

  public COD_CLIENTE: string = null;
  public USUARIO: string = null;
  public TOKEN: string = null;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.obtener_login();
    if(this.COD_CLIENTE === null || this.USUARIO === null){
      this.router.navigateByUrl('login');
    } else {
      this.router.navigateByUrl('dashboard');
    }
  }

  obtener_login(){
    if(sessionStorage.getItem('cliente')){
      this.COD_CLIENTE = sessionStorage.getItem('cliente');
    }
    if(sessionStorage.getItem('usuario')){
      this.USUARIO = sessionStorage.getItem('usuario');
    }
    if(sessionStorage.getItem('token')){
      this.TOKEN = sessionStorage.getItem('token');
    }
  }
  
}
