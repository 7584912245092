import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { LoginComponent } from './login/login.component';
import { RegistroComponent } from './registro/registro.component';
import { P404Component } from './error/p404/p404.component';
import { P500Component } from './error/p500/p500.component';
import { PerfilComponent } from '../pages/perfil/perfil.component';
import { CompartidoRoutingModule } from "./compartido-routing.module";
import { PipesModule } from "../pipes/pipes.module";
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ComponentsModule } from "../components/components.module";



@NgModule({
  declarations: [
    LoginComponent, 
    RegistroComponent, 
    P404Component, 
    P500Component, 
    PerfilComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PipesModule,
    CollapseModule.forRoot(),
    CompartidoRoutingModule,
    ComponentsModule
  ]
})
export class CompartidoModule { }
