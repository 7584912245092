import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { LoginService } from '../../services/login.service';
import { NgForm } from '@angular/forms';
import { Usuario } from '../../models/usuario.model';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  recuerdame: boolean = false;

  usuario: string;
  password: string;
  codigo: string;

  constructor(
    private router: Router,
    private _login: LoginService
  ) { }

  ngOnInit() {
  }

  ingresar( forma: NgForm) {

    console.log(forma.value.email);
    if ( forma.invalid ) {
      return;
    }

    let usuario = new Usuario(null, forma.value.email, forma.value.password );
    /*let usuario = { 
        'email': forma.value.email, 
        'password': forma.value.password 
    };*/

    this._login.login( usuario, forma.value.recuerdame )
              .subscribe( data => {
                localStorage.setItem('isLoggedin', 'true');
                this.router.navigate(['/dashboard'])  });

    /*this.login.login( usuario.email, usuario.password )
              .subscribe( correcto => {
                console.log(correcto);  
                localStorage.setItem('isLoggedin', 'true');
                this.router.navigate(['/dashboard'])  
                });*/

    // this.router.navigate([ '/dashboard' ]);

  }
  
  /*ingresar(){
    this._login.login(this.usuario, this.password)
        .subscribe( (resp: any) => {
          console.log(resp);
          if(!resp.err){
            this.guardarSession(true, resp.data);
            swal('Login',resp.mensaje, 'success');
            this.router.navigateByUrl('dashboard');
          } else {
            this.guardarSession(false, resp.data);
            swal('Oops...',resp.mensaje, 'error');
          }
        });
  }*/

  olvidopwd(){

  }

  registro(codigo: string){
    if(codigo !== undefined){
      this._login.valida_codigo_registro(codigo)
        .subscribe( (resp: any) => {
          if(resp.data !== null){
            this.router.navigate(['/registro', codigo]);
          } else {
            swal.fire('Oops...', resp.mensaje, 'error');
          }
        });
      
    } else {
      swal.fire('Oops...', 'Debe Ingresar un Código', 'error');
    }
  }

  guardarSession(ok: boolean, data: any){
    if(ok){
      sessionStorage.setItem('cliente', data.cod_cliente);
      sessionStorage.setItem('usuario', data.nombre);
      sessionStorage.setItem('token', data.TokenCliente);
      sessionStorage.setItem('imagen', data.imagen);
    } else {
      sessionStorage.removeItem('cliente');
      sessionStorage.removeItem('usuario');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('imagen');
    }
  }

}
