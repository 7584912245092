import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagina-negocio',
  templateUrl: './pagina-negocio.component.html',
  styleUrls: ['./pagina-negocio.component.scss']
})
export class PaginaNegocioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
