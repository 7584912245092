import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS, API_KEY, HEADER } from '../config/config';
import { Usuario } from '../models/usuario.model';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  usuario: Usuario;
  usuario2: any;
  token: string;
  menu: any[] = [];
  modulos: any[] = [];
  nombre: string;
  nivel: string;
  ncliente: string;

  private loggedInStatus = false; 

  constructor(
    private router: Router,
    public http: HttpClient
  ) { 
    this.cargarStorage();
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value
  }

  get isLoggedIn() {
    return this.loggedInStatus
  }

  renuevaToken() {

    let url = URL_SERVICIOS + '/login/renuevatoken';
    url += '?token=' + this.token;

    return this.http.get( url,{ headers:{ 'API-KEY': API_KEY} } ).pipe(
                map( (resp: any) => {

                  this.token = resp.token;
                  localStorage.setItem('token', this.token );
                  console.log('Token renovado');

                  return true;
                }),
                catchError( err => {
                  this.router.navigate(['/login']);
                  swal.fire( 'No se pudo renovar token', 'No fue posible renovar token', 'error' );
                  return Observable.throw( err );
                }));


  }

  estaLogueado() {
    return ( this.token.length > 5 ) ? true : false;
  }

  cargarStorage() {

    if ( localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse( localStorage.getItem('usuario') );
      this.nombre = localStorage.getItem('nombre');
      this.nivel = localStorage.getItem('nivel');
      this.ncliente = localStorage.getItem('ncliente');
      //this.menu = JSON.parse( localStorage.getItem('menu') );
      //this.modulos = JSON.parse( localStorage.getItem('modulos') );
      console.log("CS: "+ JSON.stringify(this.modulos));
      console.log("CSM: "+ JSON.stringify(this.menu));
    } else {
      this.token = '';
      this.usuario = null;
      this.nombre = '';
      this.nivel = '';
      this.ncliente = '';
      this.menu = [];
      this.modulos = [];
    }

  }

  guardarStorage( 
    id: string, 
    token: string, 
    usuario: Usuario, 
    nombre: string,
    nivel: string,
    ncliente: string,
    menu: any, 
    modulos: any 
  ) {

    console.log(usuario);
    console.log(token);
    localStorage.setItem('id', id );
    localStorage.setItem('token', token );
    localStorage.setItem('usuario', JSON.stringify(usuario) );
    localStorage.setItem('nombre', nombre );
    localStorage.setItem('nivel', nivel );
    localStorage.setItem('ncliente', ncliente );
    localStorage.setItem('menu', JSON.stringify(menu) );
    localStorage.setItem('modulos', JSON.stringify(modulos) );
 
    this.usuario = usuario;
    this.nombre = nombre;
    this.nivel = nivel;
    this.ncliente = ncliente;
    this.token = token;
    this.menu = menu;
    this.modulos = modulos;
    console.log("GS: "+JSON.stringify(this.modulos));
    console.log("GSM: "+JSON.stringify(this.menu));
    
  }

  logout() {
    this.usuario = null;
    this.nombre = '';
    this.nivel = '';
    this.ncliente = '';
    this.token = '';
    this.menu = [];
    this.modulos = [];
  
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('nombre');
    localStorage.removeItem('nivel');
    localStorage.removeItem('ncliente');
    localStorage.removeItem('menu');
    localStorage.removeItem('modulos');
 
    this.router.navigate(['/login']);
  }

  login( usuario: Usuario, recordar: boolean = false ) {

    if ( recordar ) {
      localStorage.setItem('email', usuario.email );
    }else {
      localStorage.removeItem('email');
    }

    
    let url = URL_SERVICIOS + '/login';
    let email = usuario.email;
    let password = usuario.password;
    //return this.http.post( url, { usuario , 'API-KEY':'e10adc3949ba59abbe56e057f20f883e' } )
    return this.http.post( url, { 'email': email, 'password': password} )
                .pipe(            
                map( (resp: any) => {
                  console.log("V "+JSON.stringify(resp));
                  console.log("VER "+JSON.stringify(resp.data));
                  let rUsuario = resp.data;
                  this.guardarStorage( 
                    rUsuario.id, 
                    rUsuario.token, 
                    rUsuario.usuario,
                    rUsuario.usuario,
                    rUsuario.nivel,
                    rUsuario.ncliente, 
                    resp.menu, 
                    resp.modulos 
                  );
                  this.guardarSession(true, rUsuario);
                  return true;
                }),
                catchError( err => {
                  console.log('Error: ', err);
                  swal.fire( 'Error en el login', err.error.mensaje, 'error' );
                  return Observable.throw( err );
                }));

  }

  login1(usuario: string, password: string){
    let url = URL_SERVICIOS + '/Login';
    return this.http.post(url, { usuario, password }, { headers: HEADER }); 
  }

  registro(data: any){
    let url = URL_SERVICIOS + '/Login';
    return this.http.put(url, data, { headers: HEADER }); 
  }

  valida_codigo_registro(cod_cliente: string){
    let url = URL_SERVICIOS + '/Login/'+cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  codigo_cliente(){

  }

  guardarSession(ok: boolean, data: any){
    if(ok){
      console.log('ACA',data);
      sessionStorage.setItem('cliente', data.cod_cliente);
      sessionStorage.setItem('usuario', data.usuario);
      sessionStorage.setItem('token', data.TokenCliente);
      sessionStorage.setItem('imagen', data.imagen);
      sessionStorage.setItem('nombre', data.nombre);
      sessionStorage.setItem('nivel', data.nivel);
    } else {
      sessionStorage.removeItem('cliente');
      sessionStorage.removeItem('usuario');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('imagen');
      sessionStorage.removeItem('nombre');
      sessionStorage.removeItem('nivel');
    }
  }
}
