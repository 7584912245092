import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'estado-tabla',
  templateUrl: './estado-tabla.component.html',
  styleUrls: ['./estado-tabla.component.scss']
})
export class EstadoTablaComponent implements OnInit {

  @Input() Habilitado = null;
  @Input() TituloPos = 'Habilitado';
  @Input() TituloNeg = 'Deshabilitado';
  habilitado = false;

  constructor() { }

  ngOnInit() {
    if(this.Habilitado === '1'){
      this.habilitado = true;
    } else {
      this.habilitado = false;
    }
  }

}
