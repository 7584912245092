import { Component, OnInit, Input } from '@angular/core';
import { sinImagen } from '../../config/auxiliar';

@Component({
  selector: 'imagen-tabla',
  templateUrl: './imagen-tabla.component.html',
  styleUrls: ['./imagen-tabla.component.scss']
})
export class ImagenTablaComponent implements OnInit {

  @Input() Imagen: any = null;
  _imagen: any;

  constructor() { }

  ngOnInit() {
    if( this.Imagen !== null ){
      if(this.Imagen.length >= 5){
        this._imagen = this.Imagen;
      } else {
        this._imagen = sinImagen;  
      }
    } else {
      this._imagen = sinImagen;
    }
  }

}
