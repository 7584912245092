import { Component, OnInit } from '@angular/core';
import { sinImagen } from '../../config/auxiliar';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  nombre: string; 
  email: string; 
  usuario: string; 
  password: string; 
  nuevopass: string = '';
  imagen: any;
  img: any = sinImagen;


  constructor() { }

  ngOnInit() {
  }

  changeListener($event) : void {
    this.readThis($event.target);
  }
  
  readThis(inputValue: any): void {
    var file:File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      this.img = myReader.result;
    }
    myReader.readAsDataURL(file);
  }

  saveRecord(){

  }
  actualizarImagen(){
    
  }

}
