export * from "./clientes.service";
export * from "./codigos.service";
export * from "./estadisticas.service";
//export * from "./pois.service";
//export * from "./promociones.service";
export * from "./traduccion.service";
export * from "./websocket.service";
export * from "./usuarios.service";
//export * from "./guias.service";
export * from "./codigosqr.service";
export * from "./campanias.service";
