import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { P404Component } from './compartido/error/p404/p404.component';
import { P500Component } from './compartido/error/p500/p500.component';
import { LoginComponent } from './compartido/login/login.component';
import { RegistroComponent } from './compartido/registro/registro.component';
import { DefaultComponent } from './pages/default/default.component';


const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '404', component: P404Component },
  { path: '500', component: P500Component },
  { path: 'login', component: LoginComponent },
  { path: 'registro/:codigo', component: RegistroComponent },
  { path: '', component: DefaultComponent, 
    children: [
      { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'campanias', loadChildren: () => import('./pages/campanias/campanias.module').then(m => m.CampaniasModule) },
      { path: 'estadisticas', loadChildren: () => import('./pages/estadisticas/estadisticas.module').then(m => m.EstadisticasModule) },
      { path: 'usuarios', loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule) },
      { path: 'codigosqr', loadChildren: () => import('./pages/codigosqr/codigosqr.module').then(m => m.CodigosqrModule) },
      { path: 'plantillas', loadChildren: () => import('./pages/plantillas/plantillas.module').then(m => m.PlantillasModule) },
      { path: 'entradas', loadChildren: () => import('./pages/entradas/entradas.module').then(m => m.EntradasModule) },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
