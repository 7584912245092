import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { BootstrapModule } from "../bootstrap/bootstrap.module";
import { BotoneraTablaComponent } from './botonera-tabla/botonera-tabla.component';
import { RouterModule } from "@angular/router";
import { AlertaComponent } from './alerta/alerta.component';
import { BotoneraFormComponent } from './botonera-form/botonera-form.component';
import { ImagenTablaComponent } from './imagen-tabla/imagen-tabla.component';
import { PipesModule } from "../pipes/pipes.module";
import { ImagenFormComponent } from './imagen-form/imagen-form.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CodigoQrComponent } from './codigo-qr/codigo-qr.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { EstadoTablaComponent } from './estado-tabla/estado-tabla.component';
import { PromocionVistapreviaComponent } from './promocion-vistaprevia/promocion-vistaprevia.component';
import { PersonalizacionComponent } from './personalizacion/personalizacion.component';
import { QrComponent } from './qr/qr.component';
import { WidgetComponent } from './widget/widget.component';
import { GraficaVisitasComponent } from './grafica-visitas/grafica-visitas.component';
import { ChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { RedesSocialesComponent } from './plantillas/redes-sociales/redes-sociales.component';
import { SitioWebComponent } from './plantillas/sitio-web/sitio-web.component';
import { CuponesComponent } from './plantillas/cupones/cupones.component';
import { VCardComponent } from './plantillas/v-card/v-card.component';
import { PaginaNegocioComponent } from './plantillas/pagina-negocio/pagina-negocio.component';
import { FeedbackComponent } from './plantillas/feedback/feedback.component';
import { RatingComponent } from './plantillas/rating/rating.component';
import { VideoComponent } from './plantillas/video/video.component';
import { EventoComponent } from './plantillas/evento/evento.component';
import { FacebookComponent } from './plantillas/facebook/facebook.component';
import { AppstoresComponent } from './plantillas/appstores/appstores.component';
import { Mp3Component } from './plantillas/mp3/mp3.component';
import { PdfComponent } from './plantillas/pdf/pdf.component';
import { SorteosComponent } from './plantillas/sorteos/sorteos.component';
import { InstagramComponent } from './plantillas/instagram/instagram.component';
import { ImagenesComponentPlantilla } from './plantillas/imagenes/imagenes.component';
import { ImagenesComponent } from './imagenes/imagenes.component';

@NgModule({
  declarations: [ 
    BotoneraTablaComponent, 
    AlertaComponent, 
    BotoneraFormComponent, 
    ImagenTablaComponent, 
    ImagenFormComponent, 
    FileUploadComponent, 
    CodigoQrComponent, 
    EstadoTablaComponent, 
    PromocionVistapreviaComponent, 
    PersonalizacionComponent, 
    QrComponent, 
    WidgetComponent, 
    GraficaVisitasComponent, 
    RedesSocialesComponent, 
    SitioWebComponent, 
    CuponesComponent, 
    VCardComponent, 
    PaginaNegocioComponent, 
    FeedbackComponent, 
    RatingComponent, 
    VideoComponent, 
    EventoComponent, 
    FacebookComponent, 
    AppstoresComponent, 
    Mp3Component, 
    PdfComponent, 
    SorteosComponent, 
    InstagramComponent,
    ImagenesComponentPlantilla,
    ImagenesComponent
  ],
  imports: [ 
    CommonModule, 
    RouterModule, 
    PipesModule, 
    FormsModule, 
    FileUploadModule,
    BootstrapModule,
    NgxQRCodeModule,
    ChartsModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
  ],
  exports: [ 
    BotoneraTablaComponent, 
    BotoneraFormComponent, 
    ImagenTablaComponent, 
    ImagenFormComponent, 
    ImagenesComponent,
    CodigoQrComponent,
    EstadoTablaComponent,
    PromocionVistapreviaComponent,
    PersonalizacionComponent,
    QrComponent,
    WidgetComponent,
    GraficaVisitasComponent,
    RedesSocialesComponent, 
    SitioWebComponent, 
    CuponesComponent, 
    VCardComponent, 
    PaginaNegocioComponent, 
    FeedbackComponent, 
    RatingComponent, 
    VideoComponent, 
    EventoComponent, 
    FacebookComponent, 
    AppstoresComponent, 
    Mp3Component, 
    PdfComponent, 
    SorteosComponent, 
    InstagramComponent,
    ImagenesComponentPlantilla
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
