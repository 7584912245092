import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'decode64'
})
export class Decode64Pipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  transform(value: any, args?: any): any { 
    if(value){ 
      let img = this.domSanitizer.bypassSecurityTrustUrl(value);
      return img;
    } 
  } 

}
