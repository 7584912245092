import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-v-card',
  templateUrl: './v-card.component.html',
  styleUrls: ['./v-card.component.scss']
})
export class VCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
