import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { sinImagen, imgCargando } from 'src/app/config/auxiliar';
import { ImagenesService } from '../../services/imagenes.service';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { URL_SERVICIOS } from 'src/app/config/config';
import { HEADER, API_KEY } from '../../config/config';
import { AuxiliaresService } from '../../services/auxiliares.service';

@Component({
  selector: 'imagen-form',
  templateUrl: './imagen-form.component.html',
  styleUrls: ['./imagen-form.component.scss']
})
export class ImagenFormComponent implements OnInit {

  @Input() Imagen: any = '';
  @Input() Id: any;
  @Input() Tipo: any;
  @Output() Actualizar: EventEmitter<any> = new EventEmitter<any>();
  cod_cliente: string;
  imagenesSubidas: any;
  isHTML5 = true;
  public uploader:FileUploader = new FileUploader({});
  
  constructor(
    public _imagenes: ImagenesService,
    private _aux: AuxiliaresService
  ) 
  { 
    //console.log(this.Imagen);
    //this._imagen = this.Imagen;
    
  }

  ngOnInit() {
      
    this.cod_cliente = sessionStorage.getItem('cliente');

    setTimeout(() => {
      
      this._aux.id = this.Id;
      this._aux.cod_cliente = this.cod_cliente;
      this._aux._servicio = this.Tipo;
      this.listaImagenes();
      let url = URL_SERVICIOS + '/Imagenes/'+this.cod_cliente+'/'+this.Tipo+'/'+this.Id;
      let URL: FileUploaderOptions = { 
        url: url, 
        method: 'POST', 
        isHTML5: true, 
        headers: [{ name: 'API-KEY', value: API_KEY }] 
      } 
      this.uploader = new FileUploader(URL);
      this.uploader.options.url = URL_SERVICIOS + '/Imagenes/'+this.cod_cliente+'/'+this.Tipo+'/'+this.Id;
      this.uploader.options.isHTML5 = true;
      this.uploader.options.removeAfterUpload = true;
      this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
           console.log('ImageUpload:uploaded:', item, status, response);
           this.recargar();
           //alert('File uploaded successfully');
           
       };

      //console.log('Objeto',this.Objeto);
      
    },1500);
  }

  // Cargar datos
  cargar(): Promise<any>{
    return new Promise( (resolve, reject) => {
      

    });
  }

  // Recargar componente
  recargar(){
    this.Actualizar.emit();
  }

  actualizarImagen(item: FileItem){
    console.log(item);
    item.withCredentials = false;
    item.headers = [{ name: 'API-KEY', value: API_KEY }];
    item.upload();
    //this.Actualizar.emit();
  }

  listaImagenes() {
    this._imagenes.obtener(this.cod_cliente, this.Id, this.Tipo)
        .subscribe((imagenes: any) => {
          console.log(imagenes);
          this.imagenesSubidas = imagenes.data;
        });

  }

  eliminar(item) {
    console.log('Eliminar item: ', item);
    this._imagenes.eliminar(item.cod_cliente, item.id)
        .subscribe((resp: any) => {
          console.log(resp);
        })
  }
  
}
