import { Injectable } from '@angular/core';
import { URL_SERVICIOS, HEADER } from '../config/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(
    private http: HttpClient
  ) { }

  obtener_todos(cod_cliente: string){
    let url = URL_SERVICIOS + '/Clientes/todos/' + cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener(cod_cliente: string, id: string){
    let url = URL_SERVICIOS + '/Clientes/'+cod_cliente+'/'+id;
    return this.http.get(url, { headers: HEADER } ); 
  }

  nuevo(cod_cliente: string, cliente: any){
    let url = URL_SERVICIOS + '/Clientes/'+cod_cliente;
    return this.http.put(url, cliente, { headers: HEADER }); 
  }

  editar(cod_cliente: string, id: string, cliente: any){
    let tipo = 'EDITAR';
    let url = URL_SERVICIOS + '/Clientes/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, cliente, { headers: HEADER }); 
  }

  habilitar(cod_cliente: string, id: string){
    let tipo = 'HABILITAR';
    let url = URL_SERVICIOS + '/Clientes/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '1' }, { headers: HEADER }); 
  }

  deshabilitar(cod_cliente: string, id: string){
    let tipo = 'DESHABILITAR';
    let url = URL_SERVICIOS + '/Clientes/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '0' }, { headers: HEADER }); 
  }

  eliminar(cod_cliente: string, id: string){
    let url = URL_SERVICIOS + '/Clientes/'+cod_cliente+'/'+id;
    return this.http.delete(url, { headers: HEADER }); 
  }
}
