export const navItems = [
    {
      name: 'Principal',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'info',
        text: 'Nuevo'
      }
    },
    {
      title: true,
      name: 'Gestión'
    },
    {
      name: 'Campañas',
      url: '/campanias',
      icon: 'icon-list',
      children: [
          {
              name: 'Ver Campañas',
              url: '/campanias',
              icon: 'icon-layers'
          },
          {
              name: 'Nueva',
              url: '/campanias/form',
              icon: 'icon-list'
          }
      ]
    },
    {
      name: 'Códigos',
      url: '/codigosqr',
      icon: 'fa fa-qrcode',
      children: [
          {
              name: 'Ver Codigos',
              url: '/codigosqr',
              icon: 'icon-layers'
          },
          {
            name: 'Nuevo',
            url: '/codigosqr/personalizado',
            icon: 'icon-puzzle'
        }
      ]
    },
    {
      name: 'Plantillas',
      url: '/plantillas',
      icon: 'fa fa-list',
      children: [
          {
              name: 'Ver Plantillas',
              url: '/plantillas',
              icon: 'icon-layers'
          },
          {
              name: 'Nuevo',
              url: '/plantillas/form',
              icon: 'icon-puzzle'
          }
      ]
    },
    {
      name: 'Entradas',
      url: '/entradas',
      icon: 'fa fa-list',
      children: [
          {
              name: 'Ver Entradas',
              url: '/entradas',
              icon: 'icon-layers'
          },
          {
              name: 'Nuevo Lote',
              url: '/entradas/form',
              icon: 'icon-puzzle'
          },
          {
            name: 'Ver Lote',
            url: '/entradas/lote',
            icon: 'icon-puzzle'
        }
      ]
    },
    {
      name: 'Estadísticas',
      url: '/estadisticas',
      icon: 'fa fa-qrcode',
    },
    {
      title: true,
      name: 'Administración'
    },
    {
      name: 'Usuarios',
      url: '/usuarios',
      icon: 'icon-people',
      children: [
        {
          name: 'Ver Usuarios',
          url: '/usuarios',
          icon: 'icon-layers'
        },
        {
          name: 'Nuevo',
          url: '/usuarios/form',
          icon: 'icon-user-follow'
        }
      ]
    },
    
    {
      divider: true
    },
    {
      title: true,
      name: 'Soporte',
    },
    {
      name: 'Soporte',
      url: '/pages',
      icon: 'icon-star',
      children: [
        {
          name: 'Técnico',
          url: '/login',
          icon: 'icon-star'
        },
        {
          name: 'Administrativo',
          url: '/register',
          icon: 'icon-star'
        }
      ]
    }
  ];
   