import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from "../config/config";

@Injectable({
  providedIn: 'root'
})
export class TraduccionService {

  constructor(
    public http: HttpClient
  ) { }

  traducir(origen: string, destino: string, datos: any){
    let url = URL_SERVICIOS + '/Traductor/traducirpoi/'+origen+'/'+destino;
    let texto = { data: JSON.stringify( datos )};
    
    console.log( texto );
    return this.http.post(url, texto);
  }

}

