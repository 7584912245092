import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS, API_KEY, HEADER } from '../config/config';
import { WebsocketService } from './websocket.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {

  constructor(
    public http: HttpClient,
    public _ws: WebsocketService
  ) { }

  // Catidades del Cliente
  cantidades(cod_cliente: string){
    let url = URL_SERVICIOS + '/Estadistica/'+cod_cliente;
   return this.http.get(url, { headers: HEADER }); 
  }

  
  // Cantidad de Pois del Cliente Socket
  cantidades_ws(cod_cliente: string){
    return this._ws.listen('estadisticas');
  }

  // Catidades del Cliente
  cantidades_redsocial(cod_cliente: string){
    let url = URL_SERVICIOS + '/Estadistica/redsocial/'+cod_cliente;
   return this.http.get(url, { headers: HEADER }); 
  }

  // Cantidades por Guias
  cantidades_por_guia(cod_cliente: string){
    let url = URL_SERVICIOS + '/Estadistica/clientesporguia/'+cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener() {
    //let url = URL_SERVICIOS + '/estadistica';
    //return this.http.get(url, {headers: HEADER});
    return this.http.get(URL_SERVICIOS + '/estadistica/todo').pipe(
      map((res: Response) => {
        return res['data'];
      })
      ,catchError(e => this.handleError(e)));
      
  }

  // Obtener por dia
  obtenerDia() {
    //let url = URL_SERVICIOS + '/estadistica';
    //return this.http.get(url, {headers: HEADER});
    return this.http.get(URL_SERVICIOS + '/estadistica/dia/1').pipe(
      map((res: Response) => {
        return res['data'];
      })
      ,catchError(e => this.handleError(e)));
      
  }

  // To provide error description - Manav
  private handleError(error: Response | any) {
    console.error(error.message || error);
    return throwError(error.status);
  }




}
