import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mp3',
  templateUrl: './mp3.component.html',
  styleUrls: ['./mp3.component.scss']
})
export class Mp3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
