import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebsocketService } from './websocket.service';
import { URL_SERVICIOS, HEADER } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    public http: HttpClient,
    public _ws: WebsocketService
  ) { }

  obtener_todos(cod_cliente: string){
    let url = URL_SERVICIOS + '/Usuarios/todos/' + cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener(cod_cliente: string, id: string){
    let url = URL_SERVICIOS + '/Usuarios/'+cod_cliente+'/'+id;
    return this.http.get(url, { headers: HEADER } ); 
  }

  nuevo(cod_cliente: string, usuario: any){
    let tipo = 'NUEVO';
    let url = URL_SERVICIOS + '/Usuarios/'+cod_cliente+'/0/'+tipo;
    return this.http.post(url, usuario, { headers: HEADER }); 
  }

  editar(cod_cliente: string, id: string, usuario: any){
    let tipo = 'EDITAR';
    let url = URL_SERVICIOS + '/Usuarios/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, usuario, { headers: HEADER }); 
  }

  habilitar(cod_cliente: string, id: string){
    let tipo = 'HABILITAR';
    let url = URL_SERVICIOS + '/Usuarios/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '1' }, { headers: HEADER }); 
  }

  deshabilitar(cod_cliente: string, id: string){
    let tipo = 'DESHABILITAR';
    let url = URL_SERVICIOS + '/Usuarios/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '0' }, { headers: HEADER }); 
  }

  eliminar(cod_cliente: string, id: string){
    let tipo = 'ELIMINAR';
    let url = URL_SERVICIOS + '/Usuarios/'+cod_cliente+'/'+id;
    return this.http.post(url, { headers: HEADER }); 
  }

}

