import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { URL_SERVICIOS, HEADER } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ImagenesService {

  constructor(
    private http: HttpClient
  ) { }

  obtener_todos(cod_cliente: string){
    let url = URL_SERVICIOS + '/Imagenes/todos/' + cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener(cod_cliente: string, id: string, tipo: string){
    let url = URL_SERVICIOS + '/Imagenes/'+cod_cliente+'/'+tipo+'/'+id;
    return this.http.get(url, { headers: HEADER } ); 
  }

  nuevo(cod_cliente: string, img: File, tipo: string, id: string){
    let url = URL_SERVICIOS + '/Imagenes/'+cod_cliente+'/'+tipo+'/'+id;
    const formData = new FormData();
    formData.append('file', img, img.name);
    HEADER.append('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(url, formData, { headers: HEADER }); 
  }

  editar(cod_cliente: string, id: string, cliente: any){
    let tipo = 'EDITAR';
    let url = URL_SERVICIOS + '/Imagenes/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, cliente, { headers: HEADER }); 
  }

  habilitar(cod_cliente: string, id: string){
    let tipo = 'HABILITAR';
    let url = URL_SERVICIOS + '/Imagenes/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '1' }, { headers: HEADER }); 
  }

  deshabilitar(cod_cliente: string, id: string){
    let tipo = 'DESHABILITAR';
    let url = URL_SERVICIOS + '/Imagenes/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '0' }, { headers: HEADER }); 
  }

  eliminar(cod_cliente: string, id: string){
    let url = URL_SERVICIOS + '/Imagenes/'+cod_cliente+'/'+id;
    return this.http.delete(url, { headers: HEADER }); 
  }

  convertir(cod_cliente: string, imgB64: string, cod_guia: string){
    let url = URL_SERVICIOS + '/Imagenes/convertir/'+cod_cliente+'/'+cod_guia;
    const formData = new FormData();
    formData.append('qr', imgB64);
    HEADER.append('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(url, formData, { headers: HEADER }); 
  }

  obtener_convertido(cod_cliente: string, cod_guia: string){
    let url = URL_SERVICIOS + '/Imagenes/convertir/'+cod_cliente+'/'+cod_guia;
    return this.http.get(url, { headers: HEADER }); 
  }

  
}
