import { HttpHeaders } from "@angular/common/http";

const proxyurl = 'https://corsmtc.herokuapp.com/';
//export const URL_SERVICIOS = 'http://localhost:8080/apiQR/index.php'; //'http://localhost:8080/sgpi/index.php';
//export const URL_IMAGENES = 'https://localhost:8080/apiQR'; 
export const URL_SERVICIOS = 'https://codigosqr.com.ar/api/index.php'; 
export const URL_IMAGENES = 'https://codigosqr.com.ar/api'; 
export const API_KEY = 'e10adc3949ba59abbe56e057f20f883e';
export const HEADER: HttpHeaders = new HttpHeaders();
//HEADER.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
//HEADER.append('Access-Control-Allow-Origin', '*');
//HEADER.append('Access-Control-Allow-Headers', "X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding, API-KEY");
HEADER.append('API-KEY', API_KEY);
