import { Component, OnInit } from '@angular/core';
import { navItems } from "../../compartido/_nav";
import { Router } from '@angular/router';
import { sinImagen } from '../../config/auxiliar';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  public usuario: string;
  public imagen: any = sinImagen;

  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;

  constructor(
    private router: Router
  ) { 
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  ngOnInit() {
  }

  salir(){
    sessionStorage.removeItem('cliente');
    sessionStorage.removeItem('usuario');
    sessionStorage.removeItem('token');
    setTimeout(() => {
     this.router.navigateByUrl('login');
    }, 100);
    
  }

  perfil(){
    this.router.navigate(['/perfil']);
  }

  obtener_datos(){
     this.usuario = sessionStorage.getItem('usuario');
     if(sessionStorage.getItem('imagen').length >= 5){
       this.imagen = sessionStorage.getItem('imagen');
     } 
  }

}
