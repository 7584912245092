import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var require: any;

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent implements OnInit {

  @Input() text: string = 'Bienvenido!';
  @Input() size: number = 500;
  @Input() margin: number = 10;
  @Input() dotScale: number = 0.35;
  @Input() maskedDots: boolean = false;
  @Input() colorDark: string = '#000000';
  @Input() colorLight: string = '#FFFFFF';
  @Input() correctLevel: number = 2; //AwesomeQRCode.CorrectLevel.H; //L: 1, M: 0, Q: 3, H: 2
  @Input() backgroundImage: ImageBitmap = undefined; //backgroundImg;
  @Input() backgroundDimming: string = 'rgba(0, 0, 0, 0.1)';
  @Input() logoImage: ImageBitmap = undefined; //logoImg;
  @Input() logoScale: number = 0.35;
  @Input() logoMargin: number = 6;
  @Input() logoCornerRadius: number = 8;
  @Input() logoPosition: string = 'CENTRO';
  @Input() autoColor: boolean = true;
  @Input() whiteMargin: boolean = true;
  @Input() binarize: boolean = false;
  @Input() binarizeThreshold: number = 128;
  @Input() gifBackground: any = undefined;
  //@Input() gifBackground: string = ''; //backgroundGIFArray;
  @Output() imagenQR: EventEmitter<string> = new EventEmitter<string>();
  
  
  
  constructor() { }

  ngOnInit() {
    this.generarCodigo();
  }

  public generarCodigo() {
    var self_ = this;
    var imgTemp: any = '';  
    //let AwesomeQR = require('../../../../assets/js/awesome-qr');
    let AwesomeQR = require('../../../assets/js/awesome-qr');
    

    let opciones = {
      text: this.text,
      size: this.size,
      margin: this.margin,
      dotScale: this.dotScale, // float
      maskedDots: this.maskedDots,
      colorDark: this.colorDark,
      colorLight: this.colorLight,
      correctLevel: this.correctLevel, // AwesomeQRCode.CorrectLevel.H,
      backgroundImage: this.backgroundImage, // backgroundImg,
      backgroundDimming: this.backgroundDimming,
      logoImage: this.logoImage,
      logoScale: this.logoScale, // float
      logoMargin: this.logoMargin,
      logoCornerRadius: this.logoCornerRadius,
      logoPosition: this.logoPosition,
      autoColor: this.autoColor,
      whiteMargin: this.whiteMargin,
      binarize: this.binarize,
      binarizeThreshold: this.binarizeThreshold,
      gifBackground: this.gifBackground,
      //gifBackground: this.gifBackground,
      bindElement: 'qrcode',
      callback: function (data) {
          //console.log('DESDE QR: ', data);
          imgTemp = data;
          self_.imagenQR.emit(imgTemp);      
        }
      }

      new AwesomeQR().create(opciones);
         
      
  }

  public generarCodigoImagen() {
    var self_ = this;
    var imgTemp: any = '';  
    //let AwesomeQR = require('../../../../assets/js/awesome-qr');
    let AwesomeQR = require('../../../assets/js/awesome-qr');
    

    let opciones = {
      text: this.text,
      size: this.size,
      margin: this.margin,
      dotScale: this.dotScale, // float
      maskedDots: this.maskedDots,
      colorDark: this.colorDark,
      colorLight: this.colorLight,
      correctLevel: this.correctLevel, // AwesomeQRCode.CorrectLevel.H,
      backgroundImage: this.backgroundImage, // backgroundImg,
      backgroundDimming: this.backgroundDimming,
      logoImage: this.logoImage,
      logoScale: this.logoScale, // float
      logoMargin: this.logoMargin,
      logoCornerRadius: this.logoCornerRadius,
      logoPosition: this.logoPosition,
      autoColor: this.autoColor,
      whiteMargin: this.whiteMargin,
      binarize: this.binarize,
      binarizeThreshold: this.binarizeThreshold,
      gifBackground: this.gifBackground,
      //gifBackground: this.gifBackground,
      bindElement: 'qrcode',
      callback: function (data) {
          //console.log('DESDE QR: ', data);
          imgTemp = data;
          self_.imagenQR.emit(imgTemp);      
        }
      }

      new AwesomeQR().create(opciones);
      return imgTemp;
      
  }

}
