import { Injectable } from '@angular/core';
import { URL_SERVICIOS, HEADER } from '../config/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CodigosqrService {

  constructor(
    private http: HttpClient
  ) { }

  obtener_todos(cod_cliente: string){
    let url = URL_SERVICIOS + '/Codigosqr/todos/' + cod_cliente;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_campania(cod_cliente: string, campania: string){
    let url = URL_SERVICIOS + '/Codigosqr/campania/' + cod_cliente+ '/'+ campania;
    return this.http.get(url, { headers: HEADER }); 
  }

  obtener_codigo(cod_cliente: string, urlLink: string){
    let url = URL_SERVICIOS + '/Codigosqr/codigo/'+cod_cliente;
    return this.http.post(url, { 'destino': urlLink }, { headers: HEADER } ); 
  }

  obtener(cod_cliente: string, id: string){
    let url = URL_SERVICIOS + '/Codigosqr/'+cod_cliente+'/'+id;
    return this.http.get(url, { headers: HEADER } ); 
  }

  obtener_url(cod_cliente: string, id: string){
    let url = URL_SERVICIOS + '/Codigosqr/porurl/'+cod_cliente+'/'+id;
    return this.http.get(url, { headers: HEADER } ); 
  }

  nuevo(cod_cliente: string, codigo: any){
    let tipo = 'NUEVO';
    let url = URL_SERVICIOS + '/Codigosqr/'+cod_cliente+'/0/'+tipo;
    return this.http.post(url, codigo, { headers: HEADER }); 
  }

  editar(cod_cliente: string, id: string, codigo: any){
    let tipo = 'EDITAR';
    let url = URL_SERVICIOS + '/Codigosqr/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, codigo, { headers: HEADER }); 
  }

  habilitar(cod_cliente: string, id: string){
    let tipo = 'HABILITAR';
    let url = URL_SERVICIOS + '/Codigosqr/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '1' }, { headers: HEADER }); 
  }

  deshabilitar(cod_cliente: string, id: string){
    let tipo = 'DESHABILITAR';
    let url = URL_SERVICIOS + '/Codigosqr/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { habilitado: '0' }, { headers: HEADER }); 
  }

  eliminar(cod_cliente: string, id: string){
    let tipo = 'ELIMINAR';
    let url = URL_SERVICIOS + '/Codigosqr/'+cod_cliente+'/'+id+'/'+tipo;
    return this.http.post(url, { headers: HEADER }); 
  }
}
