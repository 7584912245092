import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appstores',
  templateUrl: './appstores.component.html',
  styleUrls: ['./appstores.component.scss']
})
export class AppstoresComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
