import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpClientModule } from "@angular/common/http";
import { DataTablesModule } from 'angular-datatables';

// Modulos
//import { ComponentsModule } from "./components/components.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { FormsModule } from '@angular/forms';
import { PipesModule } from "./pipes/pipes.module";

// Plugins
import { ColorPickerModule } from 'ngx-color-picker';

// Import containers
//import { DefaultLayoutComponent } from './containers';
import { DefaultComponent } from "./pages/default/default.component";
import { CompartidoModule } from "./compartido/compartido.module";


const APP_CONTAINERS = [
  //DefaultLayoutComponent
  DefaultComponent
];

// sockets
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = {
  url: environment.wsUrl , options: {}
};


import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    DefaultComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    FormsModule,
    ColorPickerModule,
    HttpClientModule,
    DataTablesModule.forRoot(),
    CompartidoModule,
    PipesModule,
    SocketIoModule.forRoot(config),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
    })
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
