import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { URL_SERVICIOS, HEADER } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  constructor(
    private http: HttpClient
  ) { }

  enviar(cod_cliente: string, email: any){
    let url = URL_SERVICIOS + '/EnvioMails/' + cod_cliente;
    return this.http.post(url, email,{ headers: HEADER }); 
  }
}
