import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {

  @Input() anchocol: number = 3;
  @Input() backcolor: string = 'info';
  @Input() forecolor: string = 'white';
  @Input() icono: string = 'location-pin';
  @Input() cantidad: number = 0;
  @Input() titulo: string = 'Campañas';
  @Input() porcentaje: number = 100;
  

  constructor() { }

  ngOnInit() {
  }

}
