import { Component, OnInit, Input } from '@angular/core';
import { ImagenesService } from '../../services/imagenes.service';
import { EmailsService } from '../../services/emails.service';

@Component({
  selector: 'codigo-qr',
  templateUrl: './codigo-qr.component.html',
  styleUrls: ['./codigo-qr.component.scss']
})
export class CodigoQrComponent implements OnInit {

  @Input() codigoParcial = null;
  @Input() guiaId = null;
  codigoQR = null;
  baseurl = 'https://app.ururecoleta.com.ar/home';
  cod_cliente: string = '';
  imageData = '';
  imageString = '';
  

  constructor(
    private _is: ImagenesService,
    private _es: EmailsService
  ) { }

  ngOnInit() {
    this.cod_cliente = sessionStorage.getItem('cliente');
    this.codigoQR = this.baseurl + '/'+ this.cod_cliente +'/' + this.codigoParcial;
    //this.obtenerImagen();
  }

  /*getImage(){
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
    this.imageData = canvas.toDataURL("image/jpeg").toString();
    //console.log(this.imageData);
    this.guardarImagenQr();
  }

  obtenerImagen(){
    this._is.obtener_convertido(this.cod_cliente, this.codigoParcial)
        .subscribe((resp: any) => {
          console.log(resp);
          this.imageString = resp.ID;
        });
  }

  guardarImagenQr(){
    this._is.convertir(this.cod_cliente,this.imageData,this.codigoParcial)
        .subscribe((resp: any) => {
          console.log(resp);
          this.imageString = resp.ID;
        });
  }

  enviar(){
    if(this.imageString === ''){
      this.guardarImagenQr();
    }
    let email = {
      para: 'mauro@mtcsistemas.com.ar',
      nombre: 'Mauro',
      asunto: 'Prueba QR',
      mensaje: 'Prueba desde Angular',
      qr: this.imageString
    };
    this._es.enviar(this.cod_cliente, email)
        .subscribe((resp: any) => {
          console.log(resp);
        });
  }*/

}
